// Services Component
import React from "react"
import './css/services.css'

export default function Services() {
        return (
            <section className="services main-grid"id="services">
                <h2 className="section-title">Services</h2>
    			<div className="left-col">
                <h3 className="services__subtitle">What I do?</h3>
    				<p className="services-desc">
    					I provide all the web services that you may need to
    					take you business or project to the next level.
    					If it's web design, development or support, I'm here
    					to offer technical expertise to assist you.
    					Get in touch so we can create something great together.
    				</p>
                    <a href="#contact" className="btn btn-services">Get in touch</a>
    			</div>
                <div className="right-col services-grid">
                    <div className="service">
                        <i className="lni lni-ruler-pencil service__icon"></i>
                        <h3>Web Design</h3>
    					<p>
                            Creative custom designs,
                            tailor made for your
                            business.
                        </p>
                    </div>
                    <div className="service">
                    <i className="lni lni-code service__icon"></i>
                        <h3>Web Development</h3>
                        <p>
                            Responsive, functional
                            designs using the latest
                            web technologies.
                        </p>
                    </div>
                    <div className="service">
                        <i className="lni lni-clipboard service__icon"></i>
                        <h3>SEO</h3>
                        <p>
                            Search Engine Optimization, implementing
                            practices that will assist your website to rank higher in
                            search engines, attracting
                            more organic traffic and
                            potential customers.
                        </p>
                    </div>
    				<div className="service">
                        <i className="lni lni-support service__icon"></i>
                        <h3>Support</h3>
                        <p>
                            Support & Maintenance,
                            ensuring your website
                            remains up-to-date and fully
                            functional.
                        </p>
                    </div>
                </div>
            </section>
        )
}

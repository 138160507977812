import React from "react"
import './css/about.css'
import design from './css/img/headshot_bw_4.jpg'


export default function About() {
        return (
            <section className="about main-grid" id="about">
                <h2 className="section-title">About Me</h2>
    			<div className="about-img-cont">
    				<img src={design} alt="developer coding" className="about-image" />
    			</div>
    			<div className="desc-wrapper">
                    <p className="about-desc">
                    I am a freelance web developer with over 5 years of experience crafting
                    visually appealing and functional web solutions to meet the specific needs
                    and objectives of clients.
                    If its a custom website, web application or you want find ways to increase
                    your online presence, I'm here to assist you to find a solution to suit
                    your business needs.

                    </p>
                    <h3 className="skills-title">Skills</h3>
    				<ul className="skills-list">
    					<li className="skill">React</li>
    					<li className="skill">Javascript</li>
    					<li className="skill">Python</li>
    					<li className="skill">Django</li>
    					<li className="skill">HTML</li>
    					<li className="skill">CSS</li>
    					<li className="skill">MaterialiseCSS</li>
    					<li className="skill">Bootstrap</li>
                        <li className="skill">UI / UX Design</li>
    					<li className="skill">Adobe XD</li>
                        <li className="skill">WordPress</li>
                        <li className="skill">Wix</li>
    				</ul>
    			</div>
            </section>
        )
}

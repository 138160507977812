import React from "react"
import './css/footer.css'


export default function Footer(props) {
        return (
            <footer className="footer main-grid" id="contact">
        		<div className="footer-desc">
        			<h4 className="footer-title">Design for purpose.</h4>
        			<p className="footer-subtitle">Maz Musa Designs</p>
        			<p className="footer-details">
                        Your success is my ultimate goal. I'm passionate about helping you achieve
                        greatness online. Choose a web service that will assist you with achieving your goals,
                        and we can hopefully create something amazing.
        			</p>
        		</div>
        		<div className="footer-contacts">
                    <div className="contacts-cont-top">
            			<ul className="footer-links">
            				<li>
            					<a href="/">Home</a>
            				</li>
            				<li>
            					<a href="#about">About</a>
            				</li>
            				<li>
            					<a href="#services">Services</a>
            				</li>
            				<li>
            					<a href="#portfolio">Portfolio</a>
            				</li>
            			</ul>

                    </div>
                    <div className="contacts-cont-bot">
                        <h5>Get in touch</h5>
                        <div className="footer-socials">
                            <a href={props.twitterURL} className="footer__icon"><i className="lni lni-twitter"></i></a>
                            <a href={props.linkedinURL} className="footer__icon"><i className="lni lni-linkedin"></i></a>
                        </div>
                        <div className="email-cont">
                            <a href={props.email} type="email" className="footer__icon">
                                <i className="lni lni-envelope"></i>
                            </a>
                            <span>contact@solopixeldev.co.uk</span>
                        </div>
                        <div className="phone-cont">
                            <i className="lni lni-phone footer__icon"></i>
                            <span>{props.phone}</span>
                        </div>

                    </div>
        		</div>
        		<p className="footer-copyr">Copyright &copy; solopixeldev</p>
        	</footer>
        )
}

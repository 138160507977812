// Nav component
import React from "react"
import { useState } from "react"
import './css/nav.css'
import logo from './css/img/newLogo.svg'


export default function Nav() {

        const [menuOpen, setMenuOpen] = useState(false)
        const openNav = (e) => {
            e.preventDefault()
            setMenuOpen(true)
        }

        const closeNav = (e) => {
            e.preventDefault()
            setMenuOpen(false)
        }
        return (
            <header className="header main-grid">
        		<span className="logo">
                    <img src={logo} alt="solopixeldev logo"/>
                    solopixeldev.co.uk
                </span>
        		<svg height="32px" id="menu-btn" className="open" onClick={openNav} viewBox="0 0 32 32">
        			<path fill="white" d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/>
        		</svg>

        		<nav className={menuOpen ? "nav open-nav" : "nav"} id="nav">
        			<svg viewBox="0 0 24 24" id="exit-btn" className="exit" onClick={closeNav}>
        				<path id="exit" fill="white" d="M14.8,12l3.6-3.6c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L12,9.2L8.4,5.6c-0.8-0.8-2-0.8-2.8,0   c-0.8,0.8-0.8,2,0,2.8L9.2,12l-3.6,3.6c-0.8,0.8-0.8,2,0,2.8C6,18.8,6.5,19,7,19s1-0.2,1.4-0.6l3.6-3.6l3.6,3.6   C16,18.8,16.5,19,17,19s1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8L14.8,12z" />
        			</svg>
        			<ul className="nav-list">
        				<li className="nav-link"><a href="#about">About</a></li>
        				<li className="nav-link"><a href="#services">Services</a></li>
        				<li className="nav-link"><a href="#portfolio">Portfolio</a></li>
        				<li className="nav-link"><a href="#contact">Contact</a></li>
        			</ul>
        		</nav>

        	</header>
        )
}

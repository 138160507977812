import React from "react"
import './css/hero.css'

export default function Hero() {
        return (
            <section className="hero main-grid">
    			<div className="hero__content hero__left">
    				<h1 className="hero-title">
                    Empowering businesses with creative web solutions.
                    </h1>
    				<p className="hero-subtitle">Hi I'm Maz</p>
    				<p className="hero-desc">
                    Hi, I'm Maz a Freelance Web Developer based in Surrey.
                    Through using the latest web technologies I create functional,
                    engaging and visually appealing websites that will meet your
                    business needs. Let's work together to build something amazing.
                    </p>
    				<div className="btn-wrapper">
    					<a href="#services" className="btn cta-button">Learn more</a>
    					<a href="#portfolio" className="btn cta-button">View my work</a>
    				</div>
    			</div>
    		</section>
        )
}

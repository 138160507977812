import React from "react"
import { useRef } from "react"
import './css/projects.css'

export default function Projects(props) {
    const {projectName,description,skills,siteURL,gitURL,projectImg,designImg} = props

    const modalRef = useRef(null)

    function openDialog(){
        modalRef.current.showModal()
    }

    function closeDialog(){
        modalRef.current.close()
    }

    return (
		<article className="project-card">
			<div className="project-details">
				<h3 className="project-title">{projectName}</h3>
				<p className="project-desc">{description}</p>
				<div className="cta-wrapper">


                    {   siteURL === "" ?
                        <a href="#project-model" className="project-btn btn-solid" onClick={openDialog}>View design
                            <i className='bx bx-link-external icon'></i>
                        </a> :
                        <a href={siteURL} className="project-btn btn-solid">View project
    						<i className='bx bx-link-external icon'></i>
    					</a>

                    }
                    {   gitURL === "" ?
                        "" :
                        <a href={gitURL} className="project-btn btn-outline">Github
                            <i className='bx bxl-github icon'></i>
                        </a>

                    }

				</div>
			</div>
			<div className="project-img-cont" style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, .1), rgba(0, 0, 0, .5)), url("${projectImg}")`
            }}>
				<div className="skills-wrapper">
                    {skills.map((skill, index) =>
                        <span
                            className="skill-label"
                            key = {index}
                        >
                        {skill}
                        </span>
                    )}

				</div>
			</div>

            <dialog className="modal-dialog" aria-modal="false"
                ref={modalRef}
            >
				<div className="modal">
					<img src={designImg} alt="design" className="design-image" />
                    <button type="button" name="button" className="btn-modal" onClick={closeDialog}>X</button>
                </div>
			</dialog>

		</article>

    )
}

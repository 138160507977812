// External Urls links

const dataLinks = {
    email: "mailto:contact@solopixeldev.co.uk",
    twitterURL: "https://www.twitter.com/mazmusa23",
    linkedinURL: "https://www.linkedin.com/in/maz-m-awalhe-399b1827",
    phone: "+44 000 000 000"
}

export default dataLinks

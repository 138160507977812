import React from "react"
import './css/cta.css'

export default function Cta() {
        return (
            <section className="cta-banner">
              <div className="cta-banner__wrapper">
                  <h2 className="cta-banner__title">
                  Have a great idea? or a new project, lets talk!
                  </h2>
                  <p className="cta-banner__subtitle">Start the process</p>
                  <a className="cta-banner__btn" href="#contact">Get in touch</a>
              </div>
          	</section>
        )
}

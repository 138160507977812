import React from "react"
// import { useState } from "react"
import './App.css';
import Nav from './components/Nav';
import Hero from './components/Hero';
import Services from './components/Services';
import About from './components/About';
import Projects from './components/Projects';
import Cta from './components/Cta';
import Charity from './components/Charity';
import Footer from './components/Footer';
import data from "./data";
import dataLinks from './dataLinks'

// import { v4 as uuid } from 'uuid'


export default function App() {

    // const [projectData, setProjectData] = useState(data)
    // const [links, setLinks] = useState(dataLinks)

    // const uniqID = uuid()
    // const uniqid_sm = uniqID.slice(0,8) + Math.floor(Math.random() * 100)

    const cards = data.toReversed()
    const revCards = cards.map((item) => (
        <Projects
            key = {item.id}
            {...item}
        />

    ))

return (
        <div className="App">

          <Nav/>

          <Hero/>
          <Services/>
          <About/>
          <section className="portfolio main-grid" id="portfolio">
            <h2 className="section-title">Portfolio</h2>
            {revCards}
          </section>
          <Cta/>
          <Charity/>
          <Footer
            email = {dataLinks.email}
            twitterURL = {dataLinks.twitterURL}
            linkedinURL = {dataLinks.linkedinURL}
            phone = {dataLinks.phone}
          />
        </div>
  );
}

import React from "react"
import './css/charity.css'
import charityImg from './css/img/charity-sm.jpg'

export default function CharityWork() {
        return (
            <section className="charity-section ">
                <div className="charity-section__wrapper main-grid">
                    <img src={charityImg} className="charityImg" alt="People Voluntaring" />
                    <div className="charity-right-col">
                        <h2 className="charity-section__heading">
                            Do you have web development<br/> needs for your charity?
                        </h2>
                        <p className="charity-section__desc">
                            If your a Charity or Non-Profit Organisation and require assistance with
                            any web tasks, feel free to contact me. I'm a huge believer in giving back and
                            providing support to the community in a positive way.

                        </p>
                        <a className="charity-btn"href="#contact">Let's talk!</a>
                    </div>
                </div>
            </section>

        )

}

// import v4 form 'uuid'
import { v4 as uuid } from 'uuid'

const uniqID = uuid()
const data = [
    {
        id: `${uniqID.slice(0,8)}1`,
        projectName: "AHRacing",
        description: "A motor sport racers portfolio website. This was created to promote a budding racers achievements and racing history.",
        skills: ["HTML", "Css", "Javascript"],
        siteURL: "https://ahracing.vercel.app/",
        gitURL: "",
        projectImg: require('./components/css/img/alisite_5sm.jpg'),
        designImg: ""
    },
    {
        id: `${uniqID.slice(0,8)}2`,
        projectName: "SimpleTech",
        description: "A Computer Repair business. This website was created to show all the services available in a local computer and mobile phone repair store.",
        skills: ["HTML", "CSS","Materializecss", "Javascript"],
        siteURL: "https://simple-tech.vercel.app/",
        gitURL: "",
        projectImg: require('./components/css/img/simpletech_1.png'),
        designImg: ""
    },
    {
        id: `${uniqID.slice(0,8)}3`,
        projectName: "Creative Styles",
        description: "A Beauty Salon. This website was created to show all the services available in a local Beauty Salon.",
        skills: ["HTML", "CSS", "Javascript"],
        siteURL: "",
        gitURL: "",
        projectImg: require('./components/css/img/beautyshop_2sm.jpg'),
        designImg: require('./components/css/img/salon-mock-sm.png')
    },
    {
        id: `${uniqID.slice(0,8)}4`,
        projectName: "Downtown Cycles",
        description: "A Cycling Sells and Repair business. This website was created to show all the products and services available in a local Cycling store.",
        skills: ["HTML", "CSS","Javascript"],
        siteURL: "",
        gitURL: "",
        projectImg: require('./components/css/img/cycleshop_2sm.jpg'),
        designImg: require('./components/css/img/mockdesign-sm.png')
    },
    {
        id: `${uniqID.slice(0,8)}5`,
        projectName: "Skillbase Learning",
        description: "A platform dedicated to providing learning resources within the tech industry.This site was created to allow people to easily find courses, workshops and useful learning material.",
        skills: ["Django", "Postgresql", "Javascript", "HTML", "CSS", "AWS"],
        siteURL: "https://www.skillbaselearning.com",
        gitURL: "",
        projectImg: require('./components/css/img/skillbase-hero.png'),
        designImg: ""
    },
    {
        id: `${uniqID.slice(0,8)}6`,
        projectName: "Scout",
        description: "A platform dedicated to promoting talented footballers. This website was created to allow football players to showcase their abilities in the hope to get scouted by a professional team.",
        skills: ["React", "Javascript", "HTML", "CSS", "Firebase"],
        siteURL: "",
        gitURL: "",
        projectImg: require('./components/css/img/panna-hero_2.png'),
        designImg: require('./components/css/img/panna_design_4.png')
    }
]

export default data
